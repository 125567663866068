import { useEffect, useRef, useState } from "react";
import { AssessmentButton, AttachmentIcon, showToast, TextEditor, TypeSelect } from "ui-components";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
// import AssessmentListingIcon from "../../utils/Images/AssessmentListingIcon";
import "./ViewTicket.scss";
import CreateTicketPopup from "./CreateTicketPopup";
// import SupportTicketListingIcon from "../../utils/Images/SupportTicketListingIcon";
import { getTicketById } from "../../service/SupportTicket/supportTicket";
import { capitalizeFirstLetter, convertDate, generateUUID } from "../../utils/Common/Constant";
import { getsupportTicketConversation, supportTicketConversationApi } from "../../service/Scope/Scope";
// import { setSessionStorageItem } from "../../utils/SessionStorageMethod/setSessionStorageItem";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { ImageUploader } from "../../utils/Common/MediaUploader";
import { SupportTicketsEnums } from "../../utils/Enums/SupportTicketEnums";

const ViewTicket = () => {
  const { t, } = useTranslation();
  const statusArray = [
    { label: t("GENERAL.NEW"), value: SupportTicketsEnums.STATUS_NEW },
    { label: t("GENERAL.IN_PROGRESS"), value: SupportTicketsEnums.STATUS_IN_PROGRESS },
    { label: t("GENERAL.ON_HOLD"), value: SupportTicketsEnums.STATUS_ON_HOLD },
    { label: t("GENERAL.CLOSED"), value: SupportTicketsEnums.STATUS_CLOSED },
    { label: t("GENERAL.RESOLVED"), value: SupportTicketsEnums.STATUS_RESOLVED },
  ];
  const [ticketStatus, setTicketStatus] = useState("")
  const [showCreateTicket, setShowCreateTicket] = useState<boolean>(false);
  const [ticketDetails, setTicketDetails] = useState<any | null>(null);
  const [conversationMsg, setConversationMsg] = useState([])
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [attachmentImage, setAttachmentImage] = useState<any>(null);
  const [pageRefresh, setPageRefresh] = useState(0)
  const { ticketId } = useParams();
  useEffect(() => {
    getTicketByIdHandler();
  }, []);
  useEffect(() => {
    ticketDetails?.supportTicketId && getConversation()
  }, [ticketDetails, pageRefresh])
  async function getTicketByIdHandler() {
    const response = await getTicketById(ticketId);
    if (response?.status === 200) {
      setTicketDetails(response?.data[0]);
    } else {
      showToast(response?.data?.customMessage, "error");
    }
  }

  const getConversation = async () => {
    try {
      const res = await getsupportTicketConversation(ticketDetails?.supportTicketId)
      setConversationMsg(res?.data?.rows)
    } catch (error) {
      console.error(error)
    }
  }
  const ticketData = {
    description: "",
  };
  const ticketSchema = Yup.object().shape({
    description: Yup.string().required("Description is required."),
  });
  const handleMessageText = async (value: any,
    resetForm?: () => void,
    setSubmitting?: (data: boolean) => any) => {
    const data = {
      supportTicketId: ticketDetails?.supportTicketId,
      senderType: "USER",
      senderId: "4b5d0454-c298-4d1a-91f4-0d676174639c",
      receiverId: "6fa47b6c-3837-4333-984f-073ed7c309a6",
      message: value?.description,
      attachmentUrls: [attachmentImage?.ImageUrl]
    }
    setSubmitting && setSubmitting(true);
    try {
      const res = value?.description && await supportTicketConversationApi(data)
      if (res?.status === 200) {
        setPageRefresh(Math.random() * 10)
        resetForm && resetForm();
        showToast(res?.customMessage, "success")
      }
    } catch (error) {
      console.error(error)
    }

  }
  const handleSelectCompanyLogo = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  return (
    <>
      <div className="viewTicketsSec">
        <div className="container-fluid mb-4">
          <div className="row">
            <div className="col-md-12">
              <div className="CreatedAssessmentListTitle">
                <h2>
                  Support Tickets
                  <AssessmentButton
                    onClick={() => {
                      setShowCreateTicket(true);
                    }}
                    className="whiteBtn"
                  >
                    Create New
                  </AssessmentButton>
                </h2>
              </div>
            </div>
          </div>

          <div className="row supportTicketsCards">
            <div className="col-md-8" key={Math.random()}>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12 ">
                      <div className="ticketBg m-3">
                        <div className="row">
                          <div className="col-md-12 mb-4 ticketHead">
                            <h5>
                              {" "}
                              <b>{ticketDetails?.subject}</b>
                            </h5>
                            <p dangerouslySetInnerHTML={{
                              __html: ` ${ticketDetails?.description}`,
                            }}></p>
                          </div>
                          <div className="col-md-3 mb-3">
                            <h6>Ticket ID</h6>
                            <p>{ticketDetails?.ticketNumber}</p>
                          </div>
                          <div className="col-md-3 mb-3">
                            <h6>Created by</h6>
                            <p>{ticketDetails?.createdBy?.name ? capitalizeFirstLetter(ticketDetails?.createdBy?.name) : "-"}</p>
                          </div>
                          <div className="col-md-3 mb-3">
                            <h6>Created date</h6>
                            <p>{convertDate(ticketDetails?.createdAt, false, true)} </p>
                          </div>
                          <div className="col-md-3 mb-3">
                            <h6>Last updated</h6>
                            <p>{ticketDetails?.updatedAt ? convertDate(ticketDetails?.updatedAt, false, true) : "-"} </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mb-4">
                      <div className="ticketBodyBg m-3">
                        <div className="row">
                          <div className="col-md-12 mb-4 ticketHead">
                            <h5>
                              {" "}
                              <b>Messages {conversationMsg?.length ? `(${conversationMsg.length})` : ""}</b>
                            </h5>
                            <Formik
                              initialValues={ticketData}
                              validationSchema={ticketSchema}
                              onSubmit={(
                                value,
                                { resetForm, setSubmitting },
                              ) => {
                                handleMessageText(
                                  value,
                                  resetForm,
                                  setSubmitting,
                                );
                              }}
                            >
                              {({
                                errors,
                                touched,
                                values,
                                setFieldValue,
                                isSubmitting,
                              }) => {
                                const isTextareaValid = () => {
                                  return values?.description.trim() !== "" && values?.description.trim() !== "<p><br></p>";
                                };
                                return (
                                  <Form>
                                    <div className="row">
                                    </div>
                                    <div className="row">
                                      <div className="col-md-12 mt-3">
                                        <Field name="description">
                                          {() => (
                                            <TextEditor
                                              placeholder={"New Message"}
                                              onChange={(newValue: any) => {
                                                setFieldValue(
                                                  "description",
                                                  newValue,
                                                );
                                              }}
                                              value={values.description}
                                            />
                                          )}
                                        </Field>
                                        {errors?.description &&
                                          touched?.description ? (
                                          <div className="errorMessage">
                                            {errors?.description}
                                          </div>
                                        ) : null}
                                      </div>
                                      <div className="col-md-12 mt-2">
                                        <div className="attachTicketImg">
                                          <div
                                            className="image-circle"
                                            onClick={handleSelectCompanyLogo}                                          >
                                            <input
                                              type="file"
                                              ref={fileInputRef}
                                              style={{ display: "none" }}
                                              onChange={async (e: any) => {
                                                const ImageData = await ImageUploader(
                                                  e,
                                                  `supportTicket/${generateUUID()}`,
                                                );
                                                setAttachmentImage(ImageData);
                                              }}
                                              accept="image/*"
                                            />
                                            <div className="ChooseImageIcon">
                                              <span>Add Attachments</span> &nbsp;
                                              <AttachmentIcon />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="messageSendBtn">
                                        <AssessmentButton
                                          onClick={handleMessageText}
                                          disabled={!isTextareaValid() || isSubmitting}
                                          type="submit"
                                          className="submitBtn"
                                        >
                                          {t("GENERAL.SAVE")}
                                        </AssessmentButton>
                                      </div>
                                    </div>
                                  </Form>
                                );
                              }}
                            </Formik>
                            <div className="conversation">
                              {(conversationMsg ?? [])?.map((item: any, index: any) => {
                                return (
                                  <>
                                    <div className="d-flex conversationSec">
                                      <div className="conversationHeading">
                                        <h4>{item?.companyUser?.displayName}</h4>
                                        <p>{convertDate(item?.sentAt, false, true)}</p>
                                      </div>
                                    </div>
                                    <p className="conversationPara" dangerouslySetInnerHTML={{
                                      __html: `${item?.message}`,
                                    }}></p>
                                    {index < conversationMsg.length - 1 && <hr />}
                                  </>
                                );
                              })}
                            </div>
                          </div>
                          <div className="col-md-12"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4" key={Math.random()}>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12 mb-5">
                      <div className="ticketBg m-3">
                        <div className="row">
                          <div className="col-md-12 mb-4 ticketHead">
                            <h5>
                              <b>Ticket details</b>
                            </h5>
                            <hr />
                          </div>
                          <div className="col-md-12 mb-3">
                            <h6>Status</h6>
                            <TypeSelect
                              options={statusArray}
                              placeholder={t("GENERAL.STATUS")}
                              value={ticketStatus}
                              onChange={(getValue: any) => {
                                setTicketStatus(getValue)
                              }}
                              loadingText={t("")}
                            />
                          </div>
                          <div className="col-md-12 mb-3">
                            <h6>Ticket type</h6>
                            <p>{ticketDetails?.supportTicketTypeName ?? "-"}</p>
                          </div>
                          <div className="col-md-12 mb-3">
                            <h6>Assign to</h6>
                            <p>{ticketDetails?.assignedTo ?? "-"}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >

      <CreateTicketPopup
        showCreateTicket={showCreateTicket}
        label="Create New"
        isCrossBtn={true}
        handleClose={() => {
          setShowCreateTicket(false);
        }}
      />
    </>
  );
};

export default ViewTicket;
