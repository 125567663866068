export const CompanyListEnums = Object.freeze({
  FILTER_LAST_ACTIVE: "last-active",
  FILTER_ASSESSMENT_COUNT: "assessment-count",
  FILTER_TICKET_COUNT: "ticket-count",
  FILTER_ONBOARDED_DATE: "onboarded-date",
  DESCENDING: "desc",
  ASCENDING: "asc",
  STATUS_ALL: "all",
  STATUS_ACTIVE: "active",
  STATUS_INACTIVE: "inactive",
});
