import { useTranslation } from "react-i18next";
import i18n from "../../i18nConfig";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Pagination, showToast, TemplateSearch } from "ui-components";
import DataNotAvailable from "../../utils/DataNotFound/DataNotAvailable";
import { capitalizeFirstLetter, convertDate, handleKeyPressSearch, setInQueryParams } from "../../utils/Common/Constant";
import EmailIcon from "../../utils/Images/EmailIcon";
import AssessmentListingIcon from "../../utils/Images/AssessmentListingIcon";
import PhoneIcon from "../../utils/Images/PhoneIcon";
import BackArrow from "../../utils/Images/BackArrow";
import { getPartnerClient } from "../../service/Partner/PartnerApis";

const PartnerClients = () => {
    const { t } = useTranslation();
    const currentLanguage = i18n.language;
    const createdAssessPaginationRef = useRef<HTMLDivElement>(null);
    const [searchParams] = useSearchParams();
    const searchParam = searchParams.get("search") || "";
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [partnerClientList, setpartnerClientList] = useState<any>([])
    const navigate = useNavigate();
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const timeoutRef = useRef<any>(null);
    const [managePaginationRef, setManagePaginationRef] = useState<any>(null);
    const { partnerId } = useParams();
    useEffect(() => {
        if (createdAssessPaginationRef.current && managePaginationRef != null) {
            createdAssessPaginationRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [managePaginationRef]);
    // get partner client list api call
    useEffect(() => {
        const debounceFunction = () => {
            if (timeoutRef.current !== null) {
                clearTimeout(timeoutRef.current as any);
            }
            timeoutRef.current = setTimeout(getPartnerClientApi, 500);
        };
        if (partnerId) {
            debounceFunction();
        }
        return () => clearTimeout(timeoutRef.current);
    }, [location.search, searchParam, pageSize, page]);
    const getPartnerClientApi = async () => {
        try {
            const response = await getPartnerClient(
                partnerId,
                page,
                pageSize,
                searchParam
            );
            if (response?.status === 200) {
                setpartnerClientList(response?.data?.data);
                setTotalCount(response?.data?.totalCount);
            } else {
                showToast(response?.data?.customMessage, "error");
            }
        } catch (error) {
            showToast(
                "An error occurred while fetching the companies list.",
                "error",
            );
        }
    }
    return (
        <>
            <div className="companyListWrapper CreatedAssessmentList">
                <div className="container-fluid mb-4">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="CreatedAssessmentListTitle">
                                <div className="col-md-12 ">
                                    <div className="companyNameTitle">
                                        <div className="backArrow" onClick={() => window.history.back()}>
                                            <BackArrow />
                                        </div>
                                        <div className="companyNameHeading">
                                            <span>{t("GENERAL.CLIENTS")}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="filtersSec" style={{ backgroundColor: "transparent" }}>
                            <div className="col-md-12">
                                <div
                                    className={"AssessmentListSearchSec"}
                                    ref={createdAssessPaginationRef}
                                >
                                    <TemplateSearch
                                        onChange={(e) => {
                                            setInQueryParams(queryParams, "search", e.trim(), navigate)
                                        }}
                                        placeholder={t("GENERAL.SEARCH_CLIENT")}
                                        handleKeyPress={handleKeyPressSearch}
                                        iconName="searchList"
                                        value={searchParam ?? ""}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="totalContentDiv">
                        {totalCount > 0 && (
                            <p className="totalContent">
                                {t("GENERAL.TOTAL_CLIENTS")} : {totalCount ?? "-"}
                            </p>
                        )}
                    </div>
                    <div className="row">
                        {partnerClientList?.length ? (
                            partnerClientList?.map((partnerClient: any) => {
                                return (
                                    <div className="AssessmentListContentSec" key={Math.random()}>
                                        <div className="AssessmentListCard">
                                            <div className="AssessmentListCardImg " onClick={() => {
                                                navigate(
                                                    `/${currentLanguage}/companyProfile/${partnerClient?.companyId}`,
                                                );
                                            }}>
                                                {partnerClient?.profilePic ? (
                                                    <div className="profileImage">
                                                        <img src={partnerClient?.profilePic} className="logo-img" />
                                                    </div>
                                                ) : (
                                                    <AssessmentListingIcon />
                                                )}
                                                <h4 className="mt-3"> {partnerClient?.status}</h4>
                                                <h6>{convertDate(partnerClient?.createdAt, false, true) ?? "-"}</h6>
                                            </div>
                                            <div className="AssessmentListCardDetails">
                                                <div className="ListCardDetailsTitleSec">
                                                    <div className="ListCardDetailsTitle">
                                                        <div className="listItemSec">
                                                            <div className="ListCardTitleHeading">
                                                                <div>
                                                                    <h3
                                                                        title={partnerClient?.clientName && capitalizeFirstLetter(
                                                                            partnerClient?.clientName,
                                                                        )}
                                                                        className="cursorPointer"
                                                                        onClick={() => {
                                                                            navigate(
                                                                                `/${currentLanguage}/companyProfile/${partnerClient?.companyId}`,
                                                                            );
                                                                        }}
                                                                    >
                                                                        {partnerClient?.clientName ? capitalizeFirstLetter(
                                                                            partnerClient?.clientName,
                                                                        ) : "-"}
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                            <div className="email-mobile-section">
                                                                {partnerClient?.emailId && (
                                                                    <a
                                                                        href={`mailto:${partnerClient?.emailId}`}
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        <EmailIcon /> {partnerClient?.emailId}
                                                                    </a>
                                                                )}
                                                                &nbsp; &nbsp;
                                                                {partnerClient?.mobile && (
                                                                    <a href={`tel:${partnerClient?.mobile}`}>
                                                                        <PhoneIcon /> {partnerClient?.mobile}
                                                                    </a>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ListContentSkillsSec">
                                                    <div className="ListContentSkills decreaseGap">
                                                        <div className="w-100">
                                                            <div>
                                                                <p>{t("GENERAL.REFERRAL_DATE")}</p>
                                                                <h5 className="dateSize">{partnerClient?.referralDate ? convertDate(partnerClient?.referralDate, false, true) : "-"}</h5>
                                                            </div>
                                                        </div>
                                                        <div className="w-100">
                                                            <div className="w-100">
                                                                <p className="clickable-fields">
                                                                    {t("GENERAL.ONBOARDED_DATE")}
                                                                </p>
                                                                <h5 className="dateSize">{partnerClient?.onboardedDate ? convertDate(partnerClient?.onboardedDate, false, true) : "-"}</h5>
                                                            </div>
                                                        </div>
                                                        <div className="w-100">
                                                            <div className="w-100">
                                                                <p className="clickable-fields">
                                                                    {t("GENERAL.LAST_PURCHASE")}
                                                                </p>
                                                                <h5 className="dateSize">{partnerClient?.lastPurchaseDate ? convertDate(partnerClient?.lastPurchaseDate, false, true) : "-"}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <DataNotAvailable />
                        )}
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Pagination
                                className="pagination-bar"
                                currentPage={page ?? 1}
                                totalCount={totalCount || 0}
                                pageSize={pageSize}
                                onPageChange={(page: string) => {
                                    setInQueryParams(queryParams, "page", page, navigate)
                                    setManagePaginationRef(Math.random());
                                }}
                                ofLabel={t("GENERAL.OF")}
                                resultLabel={t("GENERAL.RESULTS")}
                                setPageSize={setPageSize}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PartnerClients