import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getFeedbackList, } from "../../service/SupportTicket/supportTicket";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./FeedbackScreen.scss";
import {
    capitalizeFirstLetter,
    convertDate,
    setInQueryParams,
    truncateString,
} from "../../utils/Common/Constant";
import { Pagination, SkeletonList } from "ui-components";
import DataNotAvailable from "../../utils/DataNotFound/DataNotAvailable";
import AssessmentListingIcon from "../../utils/Images/AssessmentListingIcon";

const ReportedQuestionScreen = () => {
    const { t } = useTranslation();
    const [reportedQuestionsList, setReportedQuestionsList] = useState<any[]>([]);
    const [searchParams] = useSearchParams();
    const [pageSize, setPageSize] = useState(10);
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const [managePaginationRef, setManagePaginationRef] = useState<any>(null);
    const reportedQuestionRef = useRef<any>(null);
    const queryParams = new URLSearchParams(location.search);
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true); // Default to true, so skeleton shows initially

    const navigate = useNavigate();

    useEffect(() => {
        if (reportedQuestionRef.current && managePaginationRef != null) {
            reportedQuestionRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [managePaginationRef]);

    useEffect(() => {
        getReportedQuestionListApi();
    }, [pageSize, page]);

    // Function to get all reported questions
    const getReportedQuestionListApi = async () => {
        setIsLoading(true); // Start loading
        try {
            const res = await getFeedbackList(page, pageSize);
            if (res?.status === 200) {
                setReportedQuestionsList(res?.data?.data?.data || []);
                setTotalCount(res?.data?.data?.totalCount);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false); // Stop loading when API call finishes
        }
    };

    return (
        <div className="container-fluid reportedQuestionsList">
            <div className="assessResponseHead mt-5">
                <div className="assessResponseHeadContent">
                    <h3>{t("GENERAL.FEEDBACK")}</h3>
                </div>
            </div>

            {isLoading ? (
                <>
                    {[...Array(3)].map((_, index) => (
                        <SkeletonList
                            key={index}
                            shape="circle"
                            numberOfTitles={1}
                            numberofTextLines={4}
                            titleSize={10}
                            textSize={6}
                            thumbnailHeight={70}
                            thumbnailWidth={70}
                            itemsGap={10}
                            contentDetailsPosition="end"
                        />
                    ))}
                </>
            ) : reportedQuestionsList?.length > 0 ? (
                <>
                    <div
                        className="responseReviewQuestion mt-3"
                        ref={reportedQuestionRef}
                    >
                        <div className="totalContentDiv">
                            {totalCount > 0 && (
                                <p className="totalContent">
                                    {t("GENERAL.TOTAL_FEEDBACKS")} : {totalCount ?? "-"}
                                </p>
                            )}
                        </div>
                        {reportedQuestionsList?.map((item: any, index: number) => {
                            return (
                                <div className="AssessmentListContentSec" key={index}>
                                    <div className="AssessmentListCard">
                                        <div className="AssessmentListCardImg ">
                                            {item?.logo ? (
                                                <div className="profileImage">
                                                    <img src={item?.logo} className="logo-img" />
                                                </div>
                                            ) : (
                                                <AssessmentListingIcon />
                                            )}
                                            <h4 className="mt-3">
                                                {" "}
                                                {item?.applicationStatus}
                                            </h4>
                                            {/* <h6>{convertDate(item?.createdAt, false, true) ?? "-"}</h6> */}
                                        </div>
                                        <div className="AssessmentListCardDetails">
                                            <div className="ListCardDetailsTitleSec">
                                                <div className="ListCardDetailsTitle">
                                                    <div className="listItemSec">
                                                        <div className="ListCardTitleHeading">
                                                            <div>
                                                                <h3
                                                                    title={capitalizeFirstLetter(item?.companyName)}
                                                                    className="cursorPointer"
                                                                >
                                                                    {capitalizeFirstLetter(
                                                                        truncateString(item?.companyName, 50),
                                                                    )}
                                                                </h3>
                                                                <p>{item?.industry?.name}</p>
                                                                <div className="companyAssessdetail">
                                                                    <h3 className="assessmentName" title={item?.applicantName}>
                                                                        <span>Applicant : </span>{item?.applicantName}
                                                                    </h3>
                                                                    <h3 className="assessmentName" title={item?.assessmentName}>
                                                                        <span>Assessment : </span>{item?.assessmentName}
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ListContentSkillsSec">
                                                <div className="ListContentSkills decreaseGap">
                                                    <div className="w-100">
                                                        <div>
                                                            <p className="clickable-fields">
                                                                {t("GENERAL.TIME_TAKEN")}
                                                            </p>
                                                            <h5>{item?.actualTimeTaken ?? "-"}</h5>
                                                        </div>
                                                        <div className="w-100">
                                                            <p className="clickable-fields">
                                                                {t("GENERAL.INVITED_ON")}
                                                            </p>
                                                            <h5 className="dateSize">{item?.invitedOn ? convertDate(item?.invitedOn, false, true) : "-"}</h5>
                                                        </div>
                                                    </div>
                                                    <div className="w-100">
                                                        <div className="w-100">
                                                            <p>{t("GENERAL.FINAL_SCORE")}</p>
                                                            <h5>{(item?.finalScore?.score || item?.finalScore) ?? "-"}</h5>
                                                        </div>
                                                        <div className="w-100">
                                                            <p className="clickable-fields">
                                                                {t("GENERAL.SUBMITTED_ON")}
                                                            </p>
                                                            <h5 className="dateSize">{item?.submittedDate ? convertDate(item?.submittedDate, false, true) : "-"}</h5>
                                                        </div>
                                                    </div>
                                                    <div className="w-100">
                                                        <div className="w-100">
                                                            <p className="clickable-fields">
                                                                {t("GENERAL.TOTAL_ATTEMPTS")}
                                                            </p>
                                                            <h5>{item?.totalAttempts ?? "-"}</h5>
                                                        </div>
                                                    </div>
                                                    <div className="w-100">
                                                        <div>
                                                            <p className="clickable-fields">
                                                                {t("GENERAL.RESULT_STATUS")}
                                                            </p>
                                                            <h5>{item?.resultStatus ?? "-"}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {item?.applicantFeedback &&
                                                <div className="applicant-feedback-sec">
                                                    <hr />
                                                    <div className="feedback-title">
                                                        <span>{t("GENERAL.APPLICANT_FEEDBACK")}</span>
                                                        <p className="feedback-value">{item?.applicantFeedback}</p>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Pagination
                                className="pagination-bar"
                                currentPage={page ?? 1}
                                totalCount={totalCount || 0}
                                pageSize={pageSize}
                                onPageChange={(page: any) => {
                                    setInQueryParams(queryParams, "page", page, navigate);
                                    setManagePaginationRef(Math.random());
                                }}
                                ofLabel={t("GENERAL.OF")}
                                resultLabel={t("GENERAL.RESULTS")}
                                setPageSize={setPageSize}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <div className="my-5">
                    <DataNotAvailable />
                </div>
            )}
        </div>
    );
};

export default ReportedQuestionScreen;
