import { handleError } from "../../utils/Common/Constant";
import apiClient from "../Connection/Connection";

export const companyGetAssessments = async (postData: unknown) => {
  try {
    const res = await apiClient.post(
      "/company/companyGetAssessmentList",
      postData,
    );
    return res?.data;
  } catch (error) {
    handleError(error);
  }

  // try {
  //   const res = await apiClient.get("company");
  //   return res?.data;
  // } catch (error) {
  //   handleError(error);
  // }
};
