import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getReportedQuestionList } from "../../service/SupportTicket/supportTicket";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./ReportedQuestionScreen.scss";
import { convertDate, setInQueryParams } from "../../utils/Common/Constant";
import { Pagination, SkeletonList } from "ui-components";
import DataNotAvailable from "../../utils/DataNotFound/DataNotAvailable";

const ReportedQuestionScreen = () => {
    const { t } = useTranslation();
    const [reportedQuestionsList, setReportedQuestionsList] = useState<any[]>([]);
    const [searchParams] = useSearchParams();
    const [pageSize, setPageSize] = useState(10);
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const [managePaginationRef, setManagePaginationRef] = useState<any>(null);
    const reportedQuestionRef = useRef<any>(null);
    const queryParams = new URLSearchParams(location.search);
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true); // Default to true, so skeleton shows initially

    const navigate = useNavigate();

    useEffect(() => {
        if (reportedQuestionRef.current && managePaginationRef != null) {
            reportedQuestionRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [managePaginationRef]);

    useEffect(() => {
        getReportedQuestionListApi();
    }, [pageSize, page]);

    // Function to get all reported questions
    const getReportedQuestionListApi = async () => {
        setIsLoading(true); // Start loading
        try {
            const res = await getReportedQuestionList(page, pageSize);
            if (res?.status === 200) {
                setReportedQuestionsList(res?.data?.data?.data || []);
                setTotalCount(res?.data?.data?.totalCount);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false); // Stop loading when API call finishes
        }
    };

    return (
        <div className="container-fluid reportedQuestionsList">
            <div className="assessResponseHead mt-5">
                <div className="assessResponseHeadContent">
                    <h3>{t("GENERAL.REPORTED_QUESTIONS")}</h3>
                </div>
            </div>

            {isLoading ? (
                <>
                    {[...Array(3)].map((_, index) => (
                        <SkeletonList
                            key={index}
                            shape="circle"
                            numberOfTitles={1}
                            numberofTextLines={4}
                            titleSize={10}
                            textSize={6}
                            thumbnailHeight={70}
                            thumbnailWidth={70}
                            itemsGap={10}
                            contentDetailsPosition="end"
                        />
                    ))}
                </>
            ) : (reportedQuestionsList?.length > 0 ?
                <>
                    <div className="responseReviewQuestion mt-3" ref={reportedQuestionRef}>
                        <div className="totalContentDiv">
                            {totalCount > 0 && (
                                <p className="totalContent">
                                    {t("GENERAL.TOTAL_REPORTED_QUESTIONS")} : {totalCount ?? "-"}
                                </p>
                            )}
                        </div>
                        {reportedQuestionsList?.map((item: any, index: number) => (
                            <div className={"assessResponseList"} key={index}>
                                <div className="assessQuestion">
                                    <div className="questionDetails">
                                        <div className="companyAssessdetail">
                                            <h3 className="assessmentName" title={item?.companyName}>
                                                <span>Company : </span>{item?.companyName}
                                            </h3>
                                            <h3 className="assessmentName" title={item?.assessmentName}>
                                                <span>Assessment : </span>{item?.assessmentName}
                                            </h3>
                                        </div>
                                        <hr />
                                        <div className="typeStatus justify-content-between ">
                                            <div className="bulletSec">
                                                <div className="questionSkillName">
                                                    {item?.isCustomQuestion
                                                        ? t("GENERAL.CUSTOM_QUESTION")
                                                        : `${item?.skillName} - ${item?.level}`}
                                                </div>
                                                <p className="d-flex">
                                                    {t("GENERAL.TYPE")} :
                                                    <span className="answerType">
                                                        {item?.questionType}
                                                        {item?.questionType === "Video" && !item?.isCustomQuestion && (
                                                            <span className="reportedAiIcon">
                                                                {/* <AiStarIcon /> */}
                                                            </span>
                                                        )}
                                                    </span>
                                                </p>
                                            </div>
                                            <div>
                                                <span className="reportedBy">
                                                    {t("GENERAL.REPORTED_BY")} : {item?.reportedBy} <span className="seperationPipe">|</span> {t("GENERAL.REPORTED_AT")} : {item?.reportedAt ? convertDate(item?.reportedAt, false, true) : "-"}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex gap-2">
                                        <p>{index + 1}.</p>
                                        <p dangerouslySetInnerHTML={{ __html: ` ${item?.question?.question}` }} />
                                    </div>
                                    <hr />
                                    <div className="reportedText-sec">
                                        <span className="reportedTextHeading">
                                            {t("GENERAL.REPORTED_TEXT")}
                                        </span>
                                        <br />
                                        <span className="reportedText">{item?.comment}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Pagination
                                className="pagination-bar"
                                currentPage={page ?? 1}
                                totalCount={totalCount || 0}
                                pageSize={pageSize}
                                onPageChange={(page: any) => {
                                    setInQueryParams(queryParams, "page", page, navigate);
                                    setManagePaginationRef(Math.random());
                                }}
                                ofLabel={t("GENERAL.OF")}
                                resultLabel={t("GENERAL.RESULTS")}
                                setPageSize={setPageSize}
                            />
                        </div>
                    </div>
                </> : <div className="my-5"><DataNotAvailable /></div>
            )}
        </div>
    );
};

export default ReportedQuestionScreen;
