import { APIClient } from "smartassess-api-lib";
const apiClient = new APIClient();
const adminAccessToken: any = localStorage.getItem("adminAccessToken");
// apiClient.initializeApiClient(`${process.env.REACT_APP_API}`);
apiClient.initializeApiClient(
  `${process.env.REACT_APP_API}`,
  adminAccessToken,
  false,
);
export default apiClient;
