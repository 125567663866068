import DataNotAvailable from "../../../utils/DataNotFound/DataNotAvailable";

const OpenTickets = () => {
  return (
    <div className="p-5">
      <DataNotAvailable />
    </div>);
};

export default OpenTickets;
