import { uploadMultiPart } from "smartassess-api-lib";
import { s3UploadApis } from "./Constant";

export const ImageUploader = async (e: any, path?: string) => {
  const fileData = [];
  for (let i = 0; i < e.target.files.length; i += 1) {
    fileData.push(e.target.files[i]);
  }
  const jsonData = {
    file: fileData,
    path: path,
    startUploadEndPoint: s3UploadApis.startupload,
    getPresignedEndPoint: s3UploadApis.getPresigned,
    completeUploadEndPoint: s3UploadApis.completeUpload,
  };
  const imageData = await uploadMultiPart(jsonData);
  e.target.value = "";
  return imageData?.data?.data;
};
// This function work for multifile upload.
export const MultiFileUploader = async (e: any, path?: string) => {
  const fileData = [e];
  const jsonData = {
    file: fileData,
    path: path,
    startUploadEndPoint: s3UploadApis.startupload,
    getPresignedEndPoint: s3UploadApis.getPresigned,
    completeUploadEndPoint: s3UploadApis.completeUpload,
  };
  const imageData = await uploadMultiPart(jsonData);
  return imageData?.data?.data;
};
