import { useEffect, useRef, useState } from "react";
import {
  Pagination,
  showToast,
} from "ui-components";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  capitalizeFirstLetter,
  convertDate,
  handleKeyPressSearch,
  setInQueryParams,
} from "../../utils/Common/Constant";
import "./ListTicket.scss";
import CreateTicketPopup from "./CreateTicketPopup";
import SupportTicketListingIcon from "../../utils/Images/SupportTicketListingIcon";
import { getSupportTickets } from "../../service/SupportTicket/supportTicket";
import DataNotAvailable from "../../utils/DataNotFound/DataNotAvailable";
import AssessmentListingIcon from "../../utils/Images/AssessmentListingIcon";
import { SupportTicketsEnums } from "../../utils/Enums/SupportTicketEnums";
import { CompanyListEnums } from "../../utils/Enums/CompanyListEnums";
import FilterSection from "../../components/FilterSection/FilterSection";
const ListTicket = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const queryParams = new URLSearchParams(location.search);
  const initialFilterKey = [SupportTicketsEnums.FILTER_CREATED_DATE].find((key) => queryParams.has(key));
  const initialFilterValue = initialFilterKey
    ? queryParams.get(initialFilterKey) || ""
    : "";
  const [createdDate, setCreatedDate] = useState(
    initialFilterKey === SupportTicketsEnums.FILTER_CREATED_DATE && initialFilterValue === CompanyListEnums.ASCENDING
      ? true
      : false,
  );
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const searchParam = searchParams.get(SupportTicketsEnums.FILTER_SEARCH) || "";
  const createdDateParam = searchParams.get(SupportTicketsEnums.FILTER_CREATED_DATE);
  const supportTypeParam = searchParams.get(SupportTicketsEnums.FILTER_SUPPORT_TYPE) || SupportTicketsEnums.TYPE_ALL;
  const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
  const ticketStatus = searchParams.get("status") || "";
  const [managePaginationRef, setManagePaginationRef] = useState<any>(null);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const timeoutRef = useRef<any>(null);
  const [ticketList, setTicketList] = useState<any>([]);
  const [activeFilter, setActiveFilter] = useState({
    key: initialFilterKey || SupportTicketsEnums.FILTER_CREATED_DATE,
    value: initialFilterValue || CompanyListEnums.ASCENDING,
  });
  const [isResetDisable, setIsResetDisable] = useState(false);
  const createdAssessPaginationRef = useRef<HTMLDivElement>(null);
  const [selectedTicketStatus, setSelectedTicketStatus] = useState({
    value: "",
    label: "",
  });
  useEffect(() => {
    setSelectedTicketStatus({ value: ticketStatus, label: ticketStatus });
  }, [ticketStatus]);
  const defaultStatus = [
    { label: t("GENERAL.NEW"), value: SupportTicketsEnums.STATUS_NEW },
    { label: t("GENERAL.IN_PROGRESS"), value: SupportTicketsEnums.STATUS_IN_PROGRESS },
    { label: t("GENERAL.ON_HOLD"), value: SupportTicketsEnums.STATUS_ON_HOLD },
    { label: t("GENERAL.CLOSED"), value: SupportTicketsEnums.STATUS_CLOSED },
    { label: t("GENERAL.RESOLVED"), value: SupportTicketsEnums.STATUS_RESOLVED },
  ];
  const [showCreateTicket, setShowCreateTicket] = useState<boolean>(false);
  const [shouldRefresh, setShouldReferesh] = useState(false);
  useEffect(() => {
    if (activeFilter.key && activeFilter.value) {
      [SupportTicketsEnums.FILTER_CREATED_DATE].forEach((key) => {
        if (key !== activeFilter.key) {
          queryParams.delete(key);
        }
      });
      setInQueryParams(
        queryParams,
        activeFilter.key,
        activeFilter.value,
        navigate,
      );
    }
    const containsLastActiveAsc = queryParams.get(SupportTicketsEnums.FILTER_CREATED_DATE) === CompanyListEnums.ASCENDING;
    const isSearchText = searchParam === "";
    const isSupportType = supportTypeParam === SupportTicketsEnums.TYPE_ALL;
    const isTicketStatus = ticketStatus === "";
    setIsResetDisable(
      containsLastActiveAsc && isSearchText && isSupportType && isTicketStatus,
    );
  }, [activeFilter, searchParam, supportTypeParam, ticketStatus]);
  useEffect(() => {
    // Function to handle debouncing
    const debounceFunction = () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current as any); // Cast to any
      }
      timeoutRef.current = setTimeout(getSupportTicketList, 500);
    };
    debounceFunction();
    return () => clearTimeout(timeoutRef.current);
  }, [location.search, searchParam, pageSize, shouldRefresh, activeFilter, ticketStatus]);

  useEffect(() => {
    if (createdAssessPaginationRef.current && managePaginationRef != null) {
      createdAssessPaginationRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [managePaginationRef]);

  // to get assessment list
  async function getSupportTicketList() {
    const search = searchParam?.trim() ?? "";
    const createdDate = createdDateParam?.trim() ?? "";
    const supportType = supportTypeParam?.trim() ?? "";
    const response = await getSupportTickets(
      page,
      pageSize,
      search,
      createdDate,
      supportType,
      ticketStatus
    );
    if (response?.status === 200) {
      setTicketList(response?.data?.data);
      setTotalCount(response?.data?.totalCount);
    } else {
      showToast(response?.data?.customMessage, "error");
    }
  }
  // reset all filters
  const clearAllFilter = () => {
    setActiveFilter({ key: SupportTicketsEnums.FILTER_CREATED_DATE, value: CompanyListEnums.ASCENDING });
    const url = `/${currentLanguage}/ticket/list`;
    navigate(url, { replace: true });
  };
  const handleFilterToggle = (key: any) => {
    setActiveFilter((prevFilter) => {
      if (prevFilter.key === key) {
        return { key, value: prevFilter.value === CompanyListEnums.ASCENDING ? CompanyListEnums.DESCENDING : CompanyListEnums.ASCENDING };
      }
      return { key, value: CompanyListEnums.DESCENDING };
    });
  };
  const handleFilterClick = (filterType: string) => {
    setCreatedDate(filterType === SupportTicketsEnums.FILTER_CREATED_DATE ? !createdDate : false);
    handleFilterToggle(filterType);
  };
  const statusOptions = [
    { value: SupportTicketsEnums.TYPE_ALL, label: t("GENERAL.ALL"), checked: supportTypeParam === SupportTicketsEnums.TYPE_ALL },
    { value: SupportTicketsEnums.TYPE_BUG, label: t("GENERAL.BUG"), checked: supportTypeParam === SupportTicketsEnums.TYPE_BUG },
    { value: SupportTicketsEnums.TYPE_TECHNICAL, label: t("GENERAL.TECHNICAL"), checked: supportTypeParam === SupportTicketsEnums.TYPE_TECHNICAL },
    { value: SupportTicketsEnums.TYPE_SALES, label: t("GENERAL.SALES"), checked: supportTypeParam === SupportTicketsEnums.TYPE_SALES },
  ];
  const sortingFilters = [
    {
      label: t("GENERAL.CREATED_DATE"),
      filterKey: SupportTicketsEnums.FILTER_CREATED_DATE,
      isActive: queryParams.get(SupportTicketsEnums.FILTER_CREATED_DATE),
    },
  ]
  return (
    <>
      <div className="companyListWrapper CreatedAssessmentList ticketListWrapper">
        <div className="container-fluid mb-4">
          <div className="row">
            <FilterSection
              title={t("GENERAL.SUPPORT_TICKETS")}
              statusOptions={statusOptions}
              queryParams={queryParams}
              navigate={navigate}
              setInQueryParams={setInQueryParams}
              searchParam={searchParam}
              handleKeyPressSearch={handleKeyPressSearch}
              clearAllFilter={clearAllFilter}
              isResetDisable={isResetDisable}
              dropDownOptions={defaultStatus}
              handleFilterClick={handleFilterClick}
              pageName="tickets"
              sortingFilters={sortingFilters}
              selectedTicketStatus={selectedTicketStatus}
              setShowCreateTicket={setShowCreateTicket}
            />
          </div>
          <div className="totalContentDiv">
            {totalCount > 0 && (
              <p className="totalContent">
                {t("GENERAL.TOTAL_TICKETS")} : {totalCount ?? "-"}
              </p>
            )}
          </div>
          <div className="row">
            {ticketList.length ? (
              ticketList.map((ticket: any) => {
                return (
                  <div className="AssessmentListContentSec" key={Math.random()}>
                    <div className="AssessmentListCard">
                      <div
                        className="AssessmentListCardImg ">
                        {ticketList?.logo ? (
                          <div className="profileImage">
                            <img src={ticket?.logo} className="logo-img" />
                          </div>
                        ) : (
                          <SupportTicketListingIcon />
                        )}
                        <h4 className="mt-3"> {ticket?.ticketNumber}</h4>
                        <h6> {ticket?.status}</h6>
                      </div>
                      <div className="AssessmentListCardDetails">
                        <div className="ListCardDetailsTitleSec">
                          <div className="ListCardDetailsTitle">
                            <div className="listItemSec">
                              <div className="ListCardTitleHeading">
                                <div>
                                  <h3
                                    title={capitalizeFirstLetter(
                                      ticket?.subject,
                                    )}
                                    className="cursorPointer"
                                    onClick={() => {
                                      navigate(
                                        `/${currentLanguage}/companyProfile/${ticket?.companyId}`,
                                      );
                                    }}
                                  >
                                    {capitalizeFirstLetter(ticket?.subject)}
                                  </h3>
                                  <div>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: ` ${ticket?.description}`,
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="listinIconDiv">
                                  <div
                                    className="cursorPointer"
                                    onClick={() => {
                                      navigate(
                                        `/${currentLanguage}/ticket/${ticket?.supportTicketId}`,
                                      );
                                    }}>
                                    <AssessmentListingIcon />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="ListContentSkillsSec">
                          <div className="ListContentSkills decreaseGap">
                            <div className="w-100">
                              <div>
                                <p>{t("GENERAL.CREATED_AT")}</p>
                                <h5 className="dateSize">
                                  {convertDate(
                                    ticket?.createdAt,
                                    false,
                                    true,
                                  ) ?? "-"}
                                </h5>
                              </div>
                            </div>
                            <div className="w-100">
                              <div className="w-100">
                                <p>{t("GENERAL.CREATED_BY")}</p>
                                <h5>{ticket?.createdBy ? capitalizeFirstLetter(ticket?.createdBy) : "-"}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <DataNotAvailable />
            )}
          </div>
          <div className="row">
            <div className="col-md-12">
              <Pagination
                className="pagination-bar"
                currentPage={page ?? 1}
                totalCount={totalCount || 0}
                pageSize={pageSize}
                onPageChange={(page: any) => {
                  setInQueryParams(queryParams, "page", page, navigate);
                  setManagePaginationRef(Math.random());
                }}
                ofLabel={t("GENERAL.OF")}
                resultLabel={t("GENERAL.RESULTS")}
                setPageSize={setPageSize}
              />
            </div>
          </div>
        </div>
      </div>

      <CreateTicketPopup
        showCreateTicket={showCreateTicket}
        label="Create New"
        isCrossBtn={true}
        setShouldReferesh={setShouldReferesh}
        handleClose={() => {
          setShowCreateTicket(false);
        }}
      />
    </>
  );
};

export default ListTicket;
