const DummyUser = ({ width, height }: any) => {
    return (
        <svg
            width={width ? width : "23"}
            height={height ? height : "32"}
            viewBox="0 0 23 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.5 2.23256C8.4268 2.23256 5.93548 4.73143 5.93548 7.81395C5.93548 10.8965 8.4268 13.3953 11.5 13.3953C14.5732 13.3953 17.0645 10.8965 17.0645 7.81395C17.0645 4.73143 14.5732 2.23256 11.5 2.23256ZM3.70968 7.81395C3.70968 3.49842 7.19752 0 11.5 0C15.8025 0 19.2903 3.49842 19.2903 7.81395C19.2903 12.1295 15.8025 15.6279 11.5 15.6279C7.19752 15.6279 3.70968 12.1295 3.70968 7.81395Z"
                fill="#2EBEC6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 25.7807C0 21.4065 3.53531 17.8605 7.89632 17.8605H15.1037C19.4647 17.8605 23 21.4065 23 25.7807C23 29.2156 20.224 32 16.7995 32H6.20046C2.77604 32 0 29.2156 0 25.7807ZM7.89632 20.093C4.76458 20.093 2.22581 22.6395 2.22581 25.7807C2.22581 27.9826 4.00532 29.7674 6.20046 29.7674H16.7995C18.9947 29.7674 20.7742 27.9826 20.7742 25.7807C20.7742 22.6395 18.2354 20.093 15.1037 20.093H7.89632Z"
                fill="#2EBEC6"
            />
        </svg>
    );
};

export default DummyUser;
