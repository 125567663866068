import DataNotAvailable from "../../utils/DataNotFound/DataNotAvailable"

const Dashboard = () => {
    return (
        <div className="p-5">
            <DataNotAvailable />
        </div>
    )
}

export default Dashboard