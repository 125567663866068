import React from "react";

const LocationIcon = () => {
    return (
        <svg
            width="12"
            height="15"
            viewBox="0 0 12 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6 14C8.5 11.4 11 9.07184 11 6.2C11 3.32812 8.76143 1 6 1C3.23858 1 1 3.32812 1 6.2C1 9.07184 3.5 11.4 6 14Z"
                stroke="#4D4D4D"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6 8.22222C7.1835 8.22222 8.14286 7.25221 8.14286 6.05556C8.14286 4.85894 7.1835 3.88889 6 3.88889C4.8165 3.88889 3.85714 4.85894 3.85714 6.05556C3.85714 7.25221 4.8165 8.22222 6 8.22222Z"
                stroke="#4D4D4D"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default LocationIcon;
