import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18n from "./i18nConfig";
import ErrorBoundary from "./utils/ErrorBoundary/ErrorBoundary";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "ui-components";
import { ThemeProvider } from "./Context/ThemeContext/ThemeContext";
import { isRtl } from "./utils/Common/Constant";
const RootComponent = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const currentLanguage = i18n.language;
    const direction = isRtl(currentLanguage) ? "rtl" : "ltr";
    document.documentElement.setAttribute("dir", direction);
    document.documentElement.setAttribute("lang", currentLanguage);
  }, [i18n.language]);

  return <App />;
};
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider>
          <BrowserRouter>
            <RootComponent />
            <ToastContainer />
          </BrowserRouter>
        </ThemeProvider>
      </I18nextProvider>
    </ErrorBoundary>
  </React.StrictMode>,
);
