import { useState } from "react";
import DownArrow from "../../utils/Images/DownArrow";
import "./Table.scss";
import { useTranslation } from "react-i18next";
export interface TableColumn<T> {
    Header: string;
    accessor: keyof T;
}

export interface TableProps<T> {
    columns: TableColumn<T>[];
    data: T[];
    detailHeading: any;
    detailValue: any;
}
const Table = <T extends Record<string, any>>({
    columns,
    data,
    detailHeading,
    detailValue,
}: TableProps<T>) => {
    const [expandedRows, setExpandedRows] = useState<number | null>(null);
    const toggleExpandRow = (index: number) => {
        setExpandedRows(expandedRows === index ? null : index);
    };
    const { t } = useTranslation();
    return (
        <div className="row mb-3 table-container ">
            <div className="tabel-header">
                {columns.map((item, index) => (
                    <div key={index} className="col">
                        <span>{item?.Header}</span>
                    </div>
                ))}
            </div>
            <div className="container event-row">
                {data.map((item, index) => (
                    <>
                        <div key={index} className="row mb-3 event-item">
                            <div className="col">
                                <span>{item.eventName}</span>
                            </div>
                            <div className="col">
                                <span>{item.commencedBy}</span>
                            </div>
                            <div className="col">
                                <span>{item.date}</span>
                            </div>
                            <div className="col">
                                <span>{item.eventValue}</span>
                            </div>
                            <div className="col">
                                <span>{item.eventDirection}</span>
                            </div>
                            <div className="col status-column">
                                <span>{item.status}</span>
                                <div
                                    className={`${expandedRows === index ? "rotate-arrow" : "downArrow-icon"}`}
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapseRow${index}`}
                                    onClick={() => toggleExpandRow(index)}
                                >
                                    <DownArrow />
                                </div>
                            </div>
                        </div>
                        <div
                            id={`collapseRow${index}`}
                            className={`row mb-3 collapse ${expandedRows === index ? "show" : ""}`}
                        >
                            <div className="col collapsable-details ">
                                <p className="details-text">
                                    Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry. Lorem Ipsum has been the industrys
                                    standard dummy text ever since the 1500s, when an unknown
                                    printer took a galley of type and scrambled it to make a type
                                    specimen book. It has survived not only five centuries,
                                </p>
                                <span className="details-text">
                                    {t("GENERAL.TOTAL_BALANCE")} :{" "}
                                </span>
                                <span className="details-total-balance">5000 $</span>
                                <div className="detail-headers row mb-3">
                                    {detailHeading?.map((item: any, index: any) => (
                                        <div key={index} className="col">
                                            <span>{item}</span>
                                        </div>
                                    ))}
                                </div>
                                <div className="detail-values row mb-3">
                                    {detailValue?.map((item: any, index: any) => (
                                        <div key={index} className="col">
                                            <span>{item?.mainBalance}</span>
                                            <span>{item?.rewardBalance}</span>
                                            <span>{item?.tax}</span>
                                            <span>{item?.burnedAmount}</span>
                                            <span>{item?.paymentMethod}</span>
                                            <span>{item?.packages}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </>
                ))}
            </div>
        </div>
    );
};

export default Table;
