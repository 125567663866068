import { useState, useRef, useEffect } from "react";
import "./ProfileSettings.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { APIClient } from "smartassess-api-lib";
import ProfileIcon from "../../utils/Images/ProfileIcon";
import NotificationIcon from "../../utils/Images/NotificationIcon";
import SettingsIcon from "../../utils/Images/SettingsIcon";
import SignoutIcon from "../../utils/Images/SignoutIcon";
import DummyUser from "../../utils/Images/DummyUser";

interface ProfileSettingsProps {
    email: any;
    profileData: any;
}
const ProfileSettings = ({ email, profileData }: ProfileSettingsProps) => {
    const [isPopoverVisible, setPopoverVisible] = useState(false);
    const popoverRef: any = useRef(null);
    const iconRef: any = useRef(null);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const togglePopover = () => {
        setPopoverVisible((prevVisible) => !prevVisible);
    };
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (
                popoverRef.current &&
                !popoverRef.current.contains(event.target) &&
                iconRef.current &&
                !iconRef.current.contains(event.target)
            ) {
                setPopoverVisible(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    //function to logout
    const handleLogout = () => {
        localStorage.removeItem("adminAccessToken");
        localStorage.removeItem("adminEmail");
        localStorage.removeItem("userId");
        APIClient.setApiToken("");
        sessionStorage.clear();
        navigate("/");
    };
    return (
        <div className="profile-settings">
            <div ref={iconRef} onClick={togglePopover}>
                {profileData?.profilePic ? (
                    <div>
                        <img
                            src={profileData?.profilePic}
                            alt="Logo"
                            className="company-logo"
                        />
                    </div>
                ) : email ? (
                    <div className="logo-place">
                        {email?.charAt(0).toLocaleUpperCase()}
                    </div>
                ) : (
                    <div className="logo-place usericon">
                        <DummyUser width={20} height={20} />
                    </div>
                )}
            </div>
            {isPopoverVisible && (
                <div className="popover" ref={popoverRef}>
                    <div className="popover-header">
                        <div className="logo-name-sec">
                            {profileData?.profilePic ? (
                                <img
                                    src={profileData?.profilePic}
                                    alt="Logo"
                                    className="company-logo"
                                />
                            ) : email ? (
                                <div className="profile-icon company-logo">
                                    {email?.charAt(0).toLocaleUpperCase()}
                                </div>
                            ) : (
                                <div className="usericon">
                                    <DummyUser width={25} height={25} />
                                </div>
                            )}
                            <div className="company-name">
                                <strong>{t("GENERAL.ADMIN")}</strong>
                                <div className="email-date-section">
                                    <span>{profileData?.email ?? email}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="popover-body">
                        <div className="tab-item popover-item ">
                            <ProfileIcon />
                            <div>{t("GENERAL.PROFILE")}</div>
                        </div>
                        <div className="tab-item popover-item">
                            <NotificationIcon />
                            <div>{t("GENERAL.NOTIFICATION")}</div>
                        </div>
                        <div className="tab-item popover-item">
                            <SettingsIcon />
                            <div>{t("GENERAL.SETTINGS")}</div>
                        </div>
                        <hr />
                        <div className="tab-item popover-item" onClick={handleLogout}>
                            <SignoutIcon />
                            <div>{t("GENERAL.LOGOUT")}</div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProfileSettings;
