
import { NavLink } from "react-router-dom";
import HeaderLogo from "../../utils/Images/HeaderLogo";
import "./Header.scss";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import ProfileSettings from "./ProfileSettings";
const Header = () => {
  const { i18n } = useTranslation();
  // Get current language
  const currentLanguage = i18n.language;
  const adminEmail = localStorage.getItem("adminEmail")
  const [profileData,] = useState<any>(null);
  const { t } = useTranslation()
  return (
    <>
      <nav className="navbar navbar-expand-lg ">
        <div className="container-fluid logo-link">
          <HeaderLogo />
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 link">
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "nav-link addBottomBorder" : "nav-link"
                  }
                  to={`/${currentLanguage}/dashboard/`}
                >
                  {t("GENERAL.DASHBOARD")}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "nav-link addBottomBorder" : "nav-link"
                  }
                  to={`/${currentLanguage}/company_list`}
                >
                  {t("GENERAL.COMPANIES")}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "nav-link addBottomBorder" : "nav-link"
                  }
                  to={`/${currentLanguage}/ticket/list`}
                >
                  {t("GENERAL.SUPPORT_TICKETS")}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "nav-link addBottomBorder" : "nav-link"
                  }
                  to={`/${currentLanguage}/accounts`}
                >
                  {t("GENERAL.ACCOUNTS")}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "nav-link addBottomBorder" : "nav-link"
                  }
                  to={`/${currentLanguage}/partners`}
                >
                  {t("GENERAL.PARTNERS")}

                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "nav-link addBottomBorder" : "nav-link"
                  }
                  to={`/${currentLanguage}/withdrawal-requests`}
                >
                  {t("GENERAL.WITHDRAWAL_REQUEST")}

                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "nav-link addBottomBorder" : "nav-link"
                  }
                  to={`/${currentLanguage}/reported_questions`}
                >
                  {t("GENERAL.REPORTED_QUESTIONS")}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "nav-link addBottomBorder" : "nav-link"
                  }
                  to={`/${currentLanguage}/feedback`}
                >
                  {t("GENERAL.FEEDBACK")}
                </NavLink>
              </li>
            </ul>
            {/* <div className="profileIconSec" onClick={() => setIsPopupOpen(!isPopupOpen)}>
              <div className="profileIcon">
                <h3 className="profileFirstLetter">A</h3>
              </div>
            </div> */}
            <div className="profileIconSec">
              <ProfileSettings email={adminEmail} profileData={profileData} />
            </div>
          </div>
        </div>
      </nav>
      <div className="borderBottomLine"></div>
      {/* {
        isPopupOpen && (<UserIconPopup />)
      } */}
    </>
  );
};

export default Header;
