export const SupportTicketsEnums = Object.freeze({
  STATUS_NEW: "new",
  STATUS_IN_PROGRESS: "in-progress",
  STATUS_ON_HOLD: "on-hold",
  STATUS_CLOSED: "closed",
  STATUS_RESOLVED: "resolved",
  FILTER_CREATED_DATE: "created-date",
  TYPE_BUG: "bug",
  TYPE_TECHNICAL: "technical",
  TYPE_SALES: "sales",
  TYPE_ALL: "all",
  FILTER_SEARCH: "search",
  FILTER_SUPPORT_TYPE: "support-type",
  APPLICATION_STATUS_SUBMITTED: "Submitted",
  APPLICATION_STATUS_DEFERRED: "Deferred",
  APPLICATION_STATUS_BARRED: "Barred",
});
